import { Line } from '../line/line.model';

export class Content {
  id: number;
  rateId: number;
  lines: Line[];

  constructor(data: any) {
    this.id = data.id;
    this.rateId = data.rateId;
    this.lines = data.contentLine.map(d => new Line(d));
    this.lines = this.lines.sort((a, b) => a.order > b.order ? 1 : -1);
  }
}
