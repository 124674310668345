export class Price {
  id: number;
  title: string;
  price: string;
  subtitle: string;

  constructor(data: any) {
    this.id = data.id;
    this.title = data.title;
    this.price = data.price;
    this.subtitle = data.subtitle;
  }
}
