import {Component, Inject, Input, OnInit} from '@angular/core';
import {Rate} from '../../../models/rate/rate.model';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-rates-modal',
  templateUrl: './rates-modal.component.html',
  styleUrls: ['./rates-modal.component.scss'],
})
export class RatesModalComponent implements OnInit {
  rate: Rate;
  isNew: boolean;

  rateForm: FormGroup;
  ready = false;

  constructor(
    public dialogRef: MatDialogRef<RatesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  ngOnInit() {
    this.rate = this.data.rate;
    this.isNew = this.data.isNew;
    if (this.isNew) {
      this.newForm();
    } else {
      this.editForm();
    }

  }

  newForm() {
    this.rateForm = new FormGroup({
      id: new FormControl(),
      icon: new FormControl(),
      priceId: new FormControl(),
      contentId: new FormControl(),
      type: new FormControl(this.data.type),
      content: new FormGroup({
        id: new FormControl(),
        rateId: new FormControl(),
        line: new FormArray([]),
      }),
      price: new FormGroup({
        id: new FormControl(),
        title: new FormControl(),
        price: new FormControl(),
        subtitle: new FormControl(),
      }),
    });
    this.addLine();
    this.ready = true;
  }

  editForm() {
    this.rateForm = new FormGroup({
      id: new FormControl(this.rate.id),
      icon: new FormControl(this.rate.icon),
      priceId: new FormControl(this.rate.priceId),
      contentId: new FormControl(this.rate.contentId),
      type: new FormControl(this.rate.type),
      content: new FormGroup({
        id: new FormControl(this.rate.content.id),
        rateId: new FormControl(this.rate.content.rateId),
        line: new FormArray([])
      }),
      price: new FormGroup({
        id: new FormControl(this.rate.price.id),
        title: new FormControl(this.rate.price.title),
        price: new FormControl(this.rate.price.price),
        subtitle: new FormControl(this.rate.price.subtitle),
      }),
    });
    this.rate.content.lines.map(line => {
      const l = new FormGroup({
        id: new FormControl(line.id),
        title: new FormControl(line.title),
        description: new FormControl(line.description),
        order: new FormControl(line.order),
      });

      // @ts-ignore
      this.rateForm.controls.content.controls.line.push(l);
    });
    this.ready = true;
  }


  removeLine(line) {
    this.rate.content.lines = this.rate.content.lines.filter(data => data.id !== line.get('id').value);
    this.editForm();
  }

  addLine() {
    const line = new FormGroup({
      id: new FormControl(),
      title: new FormControl(),
      description: new FormControl(),
      order: new FormControl(),
    });

    // @ts-ignore
    this.rateForm.controls.content.controls.line.push(line);
  }

  save() {
    this.dialogRef.close(this.rateForm.value);
  }

}
