export class Line {
  id: number;
  title: string;
  description: string;
  order: number;

  constructor(data: any) {
    this.id = data.id;
    this.title = data.line.title;
    this.description = data.line.description;
    this.order = data.order;
  }
}
