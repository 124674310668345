import {Content} from '../content/content.model';
import {Price} from '../price/price.model';

export class Rate {
  id: number;
  icon: string;
  priceId: string;
  contentId: string;
  type: number;
  content: Content;
  price: Price;

  constructor(data: any) {
    this.id = data.id;
    this.icon = data.icon;
    this.priceId = data.priceId;
    this.contentId = data.contentId;
    this.type = data.type;
    this.content = new Content(data.content);
    this.price = new Price(data.price);
  }
}
