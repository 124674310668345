import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UtilsService} from '../utils.service';
import {empty, Observable, of, Subject} from 'rxjs';
import {Rate} from '../../models/rate/rate.model';


@Injectable({
  providedIn: 'root'
})
export class RateService {

  public pagination$ = new Subject<any>();
  private url = `${environment.api}/rates`;

  constructor(private http: HttpClient, private utils: UtilsService) {
  }

  public getRates(): Observable<Rate[]> {
    return this.pagination$.pipe(
      switchMap(d => this.http.get(`${this.url}`)
        .pipe(
          catchError(err => of({data: []}))
        )),
      map((data: any) => data.data.map(d => new Rate(d)))
    );
  }

  public addRate(rate: Rate): Observable<Rate> {
    return this.http.post(`${this.url}`, rate).pipe(
      map((data: any) => new Rate(data.data))
    );
  }

  public deleteRate(id) {
    return this.http.delete(`${this.url}/${id}`);
  }

  public updateRate(rate: Rate): Observable<Rate> {
    return this.http.put(`${this.url}/${rate.id}`, rate).pipe(
      map((data: any) => new Rate(data.data))
    );
  }

}
