import {AfterViewInit, Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, AfterViewInit {
  @Output() navigate = new EventEmitter();
  @Input() scrolled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isScrolled = false;
  public isCollapsed = false;

  constructor() {
  }


  ngOnInit() {
  }

  ngAfterViewInit() {
    this.scrolled.subscribe(data => {
      this.isScrolled = data;
    });
  }

  navigateTo(element: string) {
    this.isCollapsed = false;
    this.navigate.emit(element);
  }

}
