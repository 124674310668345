import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RatesModalComponent} from './rates-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {IonicModule} from '@ionic/angular';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [RatesModalComponent],
  exports: [RatesModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    IonicModule,
    MatDialogModule
  ]
})
export class RatesModalModule {
}
